import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FiChevronRight, FiChevronLeft } from "react-icons/fi"
import { FaQuoteRight } from "react-icons/fa"

import BackgroundImage from "gatsby-background-image"

const query = graphql`
  {
    allContentfulImage(
      filter: { image: { file: { fileName: { eq: "fish-totu.jpg" } } } }
    ) {
      nodes {
        image {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
          id
        }
      }
    }

    allContentfulReview {
      nodes {
        id
        name
        quote
      }
    }
  }
`

const Review = () => {
  const data = useStaticQuery(query)
  const {
    allContentfulImage: { nodes: image },
    allContentfulReview: { nodes: reviewLists },
  } = data
  const { title } = image
  const fluid = image[0].image.fluid

  const [index, setIndex] = useState(0)

  // more logic
  useEffect(() => {
    const lastIndex = reviewLists.length - 1
    if (index < 0) {
      setIndex(lastIndex)
    }
    if (index > lastIndex) {
      setIndex(0)
    }
  }, [index, reviewLists])

  return (
    <section id="review" className="review u-margin-top-big">
      <BackgroundImage
        Tag="div"
        fluid={fluid}
        backgroundColor={`#eee`}
        className="bcg__nofullscreen u-image-blur"
        preserveStackingContext={true}
        loading="lazy"
        fadeIn
        title={title}
      >
        <div className="row">
          <div className="col-1-of-1">
            <section className="slide slide__center u-margin-bottom-big">
              {reviewLists &&
                reviewLists.map((reviewList, reviewListsIndex) => {
                  const { quote, id, name } = reviewList

                  let position = "nextSlide"
                  {
                    /* Slide logic */
                  }
                  if (reviewListsIndex === index) {
                    position = "activeSlide"
                  }
                  if (
                    reviewListsIndex === index - 1 ||
                    (index === 0 && reviewListsIndex === reviewLists.length - 1)
                  ) {
                    position = "lastSlide"
                  }

                  return (
                    <article
                      className={`slide-article u-center-text ${position}`}
                      key={id}
                    >
                      <FaQuoteRight className="review__icon" />

                      <p className="review__quote paragraph paragraph--white u-margin-top u-margin-bottom-big">
                        {quote}
                      </p>
                      <p className="paragraph paragraph--white">-- {name} --</p>
                    </article>
                  )
                })}
              <button
                className="prev prev--nobg"
                aria-label="prev"
                onClick={() => setIndex(index - 1)}
              >
                <FiChevronLeft className="svg--nobg" />
              </button>
              <button
                className="next next--nobg"
                aria-label="next"
                onClick={() => setIndex(index + 1)}
              >
                <FiChevronRight className="svg--nobg" />
              </button>
            </section>
          </div>
        </div>
      </BackgroundImage>
    </section>
  )
}

export default Review
