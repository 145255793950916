import React from "react"

import BigTitle from "./BigTitle"

const CallToAction = ({ bigTitle }) => {
  return (
    <section className="call-to-action u-margin-top-big u-margin-bottom-big">
      <BigTitle bigTitle={bigTitle} />

      <p className="paragraph paragraph--black ribbon">
        <span className="ribbon__text">
          <a href="tel:802-225-6010" className="phone-link">
            802-225-6010
          </a>
        </span>
      </p>
    </section>
  )
}

export default CallToAction
