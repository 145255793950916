import React from "react"
import { Layout, Hero, AboutUs } from "../components"
import Seo from "../components/seo"
import Features from "../components/Features"
import CallToAction from "../components/CallToAction"
import ChefRec from "../components/ChefRec"
import Menus from "../components/Menus"
import Review from "../components/Review"
import Map from "../components/map"

const HomePage = () => {
  return (
    <Layout>
      <Seo
        title="Thai restaurant in Montpelier, Vermont"
        description="The best Thai restaurant in Montpelier, Vermont, Authentic Thai noodle soup, beef noodle soup, and ramen noodle soup with our famous Thai dessert mango sticky rice."
      />
      <Hero />
      <AboutUs />
      <ChefRec />
      <CallToAction bigTitle="Call Us To Order Now" />
      <Features />
      <Menus />
      <Review />
      <Map width="100%" height="450" />
    </Layout>
  )
}

export default HomePage
