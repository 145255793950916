import React from "react"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Title from "./Title"

const query = graphql`
  {
    imageBackground: allContentfulImage(
      filter: { image: { file: { fileName: { eq: "suki-yaki.jpg" } } } }
    ) {
      nodes {
        image {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
          id
        }
      }
    }
    features: allContentfulFeatures(
      sort: { fields: contentful_id, order: DESC }
    ) {
      nodes {
        title
        image {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        description {
          id
          description
        }
      }
    }
  }
`

const Features = () => {
  const {
    imageBackground: { nodes: image },
    features: { nodes: featuresInfos },
  } = useStaticQuery(query)
  const { title } = image
  const fluid = image[0].image.fluid

  return (
    <section
      id="features"
      className="features u-margin-top-big u-margin-bottom-big"
    >
      <BackgroundImage
        Tag="div"
        fluid={fluid}
        backgroundColor={`#eee`}
        className="bcg u-image-blur"
        preserveStackingContext={true}
        loading="lazy"
        fadeIn
        title={title}
      >
        <div className="u-header-wrap u-margin-top-big u-margin-bottom-big">
          <div className="row u-page-header">
            <div className="col-1-of-1">
              <header>
                <Title
                  title="features"
                  className="heading-title heading-title--white u-margin-top-big"
                />

                <p className="heading-tertiary heading-tertiary--white">
                  Our top picks
                </p>
              </header>
            </div>
          </div>
        </div>

        <section className="section u-margin-top-big">
          <div className="row">
            <div className="col-3-of-3">
              {featuresInfos &&
                featuresInfos.map(featuresInfo => {
                  const {
                    title,
                    description: { description, id },
                  } = featuresInfo
                  const featuresImage = featuresInfo.image.fluid

                  return (
                    <article className="features__article card--white">
                      <figure key={id} className="zoom">
                        <Image
                          fluid={featuresImage}
                          fadeIn={false}
                          loading="eager"
                          objectPosition="50% 50%"
                          className="features__image"
                          alt={title}
                        />
                      </figure>

                      <div className="features__info">
                        <h3 className="heading-ordinary heading-ordinary--dark u-margin-top-small u-margin-bottom-small">
                          {title}
                        </h3>
                        <p className="paragraph paragraph--dark">
                          {description}
                        </p>
                      </div>
                    </article>
                  )
                })}
            </div>
          </div>
        </section>
      </BackgroundImage>
    </section>
  )
}

export default Features
