import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { FiChevronRight, FiChevronLeft } from "react-icons/fi"

import Title from "./Title"

const query = graphql`
  {
    contentfulChefRec: allContentfulChefRec(
      sort: { fields: number, order: ASC }
    ) {
      nodes {
        description {
          description
          id
        }
        number
        title
      }
    }
    allContentfulImage(
      filter: { image: { file: { fileName: { eq: "beef-bowl.jpg" } } } }
    ) {
      nodes {
        image {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
          id
        }
      }
    }
  }
`

const Special = () => {
  const {
    contentfulChefRec: { nodes: chefRec },
    allContentfulImage: { nodes: image },
  } = useStaticQuery(query)
  const [index, setIndex] = useState(0)
  const { title } = image
  const fluid = image[0].image.fluid

  // more logic
  // console.log(chefRec)
  useEffect(() => {
    const lastIndex = chefRec.length - 1
    if (index < 0) {
      setIndex(lastIndex)
    }
    if (index > lastIndex) {
      setIndex(0)
    }
  }, [index, chefRec])

  return (
    <section
      id="special"
      className="special u-margin-top-big u-margin-bottom-big"
    >
      <BackgroundImage
        Tag="div"
        fluid={fluid}
        backgroundColor={`#eee`}
        className="bcg__nofullscreen u-image-blur"
        preserveStackingContext={true}
        loading="lazy"
        fadeIn
        title={title}
      >
        <div className="u-header-wrap u-margin-top-big u-margin-bottom-big">
          <div className="row u-page-header">
            <div className="col-1-of-1">
              <header>
                <Title
                  title="chef recommends"
                  className="heading-title heading-title--white u-margin-top-big"
                />
                <p className="heading-tertiary heading-tertiary--white">
                  Try out some of our best selling menus!
                </p>
              </header>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="special__slide col-1-of-1">
            <section className="slide slide__center u-margin-bottom-big">
              {chefRec &&
                chefRec.map((chef, chefIndex) => {
                  const {
                    title,
                    description: { description, id },
                    number,
                  } = chef
                  {
                    /* console.log(special) */
                  }

                  let position = "nextSlide"
                  {
                    /* Slide logic */
                  }
                  if (chefIndex === index) {
                    position = "activeSlide"
                  }
                  if (
                    chefIndex === index - 1 ||
                    (index === 0 && chefIndex === chefRec.length - 1)
                  ) {
                    position = "lastSlide"
                  }

                  return (
                    <article
                      className={`slide-article u-center-text ${position}`}
                      key={id}
                    >
                      <h2 className="heading-ordinary heading-ordinary--dark u-margin-top">
                        <span>{number}. </span>
                        {title}
                      </h2>
                      <p className="paragraph paragraph--dark u-margin-top u-margin-bottom-big">
                        {description}
                      </p>
                      <a
                        href="tel:802-225-6010"
                        className="btn btn--primary u-margin-bottom-medium"
                      >
                        Order Now
                      </a>
                    </article>
                  )
                })}
              <button
                className="prev"
                aria-label="prev"
                onClick={() => setIndex(index - 1)}
              >
                <FiChevronLeft />
              </button>
              <button
                className="next"
                aria-label="next"
                onClick={() => setIndex(index + 1)}
              >
                <FiChevronRight />
              </button>
            </section>
          </div>
        </div>
      </BackgroundImage>
    </section>
  )
}

export default Special
